import React from "react";

import Testimonials from "../pages/Testimonials";
import BrowseCardSection from "./BrowseCardSection";
import FooterImagesection from "./FooterImagesection";

const CardComponent = () => {


  return (
    <div className="home-card-container">
      <div className="Browse-section">
        <div className="Find-out-how">
          <p>
            <a href="/How-to" style={{ color: "yellow" }}>Find out how</a>
          </p>
          <h1>Find the Best Seat or Charter for You </h1>
        </div>

        <BrowseCardSection />
      </div>

      <div style={{ width: '100%' }}><Testimonials /></div>
      <p className="As-featured-in">On the Radar</p>
      <FooterImagesection />

    </div>
  );
};

export default CardComponent;
