import React, { useEffect, useState, useCallback } from 'react';
import { Container } from 'reactstrap';
import BookingDetails from '../components/booking-details/bookingDetails';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocalStorage } from '../utils/useLocalStorage';
import { useFlightData } from '../data/FlightData';

const MyBookingDetails = () => {
    const [details, setDetails] = useState(null);
    const [user] = useLocalStorage("auth", {});
    const { id } = useParams();
    const { myBookingDetails, downloadBookingDetailsPDF } = useFlightData();

    const fetchBookingDetails = useCallback(async () => {
        try {
            const data = await myBookingDetails({ userid: user?.userid, booking_id: id }, user);
            if (data?.bookings.length > 0) {
                const parsedData = data?.bookings.map(flight => ({
                    ...flight,
                    flight_details: JSON.parse(flight?.flight_details)
                }));
                setDetails(parsedData[0]);
            }
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    }, [myBookingDetails, user?.userid, id]);

    useEffect(() => {
        fetchBookingDetails();
    }, [fetchBookingDetails]);

    // when click on download pdf
    const handleDownloadPdf = async () => {
        try {
            const response = await downloadBookingDetailsPDF(id, user);

            // Create a Blob from the API response
            const blob = new Blob([response], { type: "application/pdf" });

            // Create download link
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "booking-details.pdf"; // File name
            document.body.appendChild(link);

            // Auto click the link and remove it
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading pdf:", error);
        }
    };
    return (
        <Container className="mt-5 py-4" style={{ height: "auto", minHeight: "500px", width: "100%" }}>

            {
                details !== null && details !== undefined && details && (<>

                    <BookingDetails detail={details} handleDownloadPdf={handleDownloadPdf} />

                </>)
            }
        </Container>
    );
};

export default MyBookingDetails;
