import React, { useEffect, useState } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './Testimonials.css'
import { IoMdQuote } from "react-icons/io";
import { NextAndPrev } from '../utils/icon';
const testimonials = [
    {
        key: '1',
        label: 'Robust Inventory',
        content: `"Katana is the first place I look whenever I need to book flights. They make it so easy to see all my possible seat options across different carriers and platforms. Its a great resource” `,
        name: '- William L.'
    },
    {
        key: '2',
        label: 'Affordable Luxury Travel',
        content: `"Discovering Katana opened up the world of private flying to me. The platform offers affordable deals on seats and even some empty-leg flights. It is easy to use and has made the convenience of flying private incredibly accessible."`,
        name: '- Stephanie Z.'
    },
    {
        key: '3',
        label: 'Sustainable and Smart Travel',
        content: `“I always felt “wasteful” staring at unused seats during my flights. The fact that I can sell my excess seats on Katana, reducing the need for others to have to source there own means fear planes and more environmentally friendly skies”`,
        name: '- Wendy H.'
    },
];


function Testimonials() {
    useEffect(() => {
        const prevControl = document.querySelector('.carousel-control-prev');
        const nextControl = document.querySelector('.carousel-control-next');
        if (prevControl) prevControl.classList.add(window.innerWidth >= 1200 ? "carousel-control-prev-pc" : "carousel-control-prev-mobile");
        if (nextControl) nextControl.classList.add(window.innerWidth >= 1200 ? "carousel-control-next-pc" : "carousel-control-next-mobile");
    }, [])
    return (
        <>
            <div className='testimonial-cointainer'>
                <div className='testimonial-Customer-Stories'>
                    <p><a href="/About" style={{ color: "yellow" }}>Read more</a></p>
                    <h1>Customer Stories.</h1>
                    <Carousel nextIcon={<NextAndPrev />}
                        prevIcon={<NextAndPrev style={{ transform: 'rotate(180deg)' }} />} indicators={false} className='testimonials-card' interval={5000000000}>
                        {testimonials.map((testimonial) => (
                            <Carousel.Item>

                                <div className='' key={testimonial.key}>
                                    <IoMdQuote color='#FFE000' size={50} style={{ transform: 'rotate(180deg)' }} />
                                    <p>{testimonial.label}</p>
                                    <p>
                                        {testimonial.content}
                                    </p>
                                    <p>{testimonial.name}</p>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div>
                </div>
            </div>
        </>
    );
}

export default Testimonials;