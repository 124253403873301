import React from "react";
import Testimonials from "../pages/Testimonials";
import BrowseCardSection from "./BrowseCardSection";
import FooterImagesection from "./FooterImagesection";
const CardComponent = () => {

  return (
    <div className="home-card-container">
      <>
        <div className="Browse-section">
          <div className="Find-out-how">

            <p> <a href="/How-to" style={{ color: "yellow" }}>Find out how</a></p>
            <h1>Find the Best Seat or Charter for You</h1>
          </div>
          <BrowseCardSection />
        </div>
        <div className="empty-leg-page-content">
          <div>
            <h2><strong>Empty Legs</strong></h2>
            <p>An empty leg, also known as a deadhead or ferry flight, is a flight operated by a private jet without passengers on board. These flights occur when the private jet drops off passengers at their destination and needs to return to its original location empty. The empty-leg flight can then be available for charter at a reduced cost.</p>
            <p>Empty-leg flights present a unique opportunity for travelers to experience the luxury of private jet travel at a significantly reduced cost. This benefits the traveler (you) and allows the private jet company to optimize its resources. It's a win-win situation that we at Katana are proud to offer.</p>
            <p>At Katana, our goal is better resource utilization. Therefore, we aim to fill not only empty seats but also empty planes. Up to 40% of private planes depart without passengers due to required repositioning. Our goal is to eliminate this waste to benefit our Members and Partners.</p>
          </div>
          <div>
            <h2><strong>Saving Money with Empty Legs</strong></h2>
            <p>Booking an empty-leg flight with Katana is a smart financial move that not only saves you money but also provides unmatched flexibility and convenience. The search results are either price certain or 'Upon Request.' Price certain typically excludes Federal Excise Taxes (7.5%) and fuel surcharge (if any); when we confirm availability, we will typically get you a price all-in. If the cost is 'Upon Request,' Katana will reach out and negotiate the best possible price. The outcome will largely depend upon the supply and demand dynamics. Often times you can choose a nearby airport, but the operator will typically charge for the flight time and landing fees. </p>
            <p>In conclusion, booking an empty-leg flight is an excellent way to experience private jet travel at a fraction of the cost. So why wait? Contact us today to learn more about how we can help you take advantage of the benefits of an empty-leg flight and make your travel experience more enjoyable and cost-effective.</p>
          </div>
        </div>
        <div style={{ width: '100%' }}><Testimonials /></div>
        <FooterImagesection />
      </>
    </div>
  );
};

export default CardComponent;