import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import HowToVideo from "../assets/video/how-to.mp4";
import HowToThumbNail from "../assets/images/HowtoThumbnail.png";
import './common.css'

function HowTo() {
  return (
    <>
      <Card className="How-to-container" style={{ margin: 0, overflowY: "scroll" }}>
        <CardBody>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>WELCOME</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              Welcome to Katana!
              <br />
              My name is Sam, and I will guide you as you learn to share planes and save money on Katana.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>SEARCH</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              <h3>Simply Enter:</h3>
              <ul className="ml-5">
                <li>Origin Airport/City (From)</li>
                <li>Destination (To)</li>
                <li>Date of departure</li>
              </ul>
            </Col>
            <Col lg="12" md="12" sm="12" id="seats-on-private-planes">
              You can dive right in at  <a href="https://www.flykatana.com">https://www.flykatana.com</a>  and Search the offerings of Seats, Empty
              Leg Charters, and Flights Interest. The results will display a comprehensive list of travel options available in the Katana system, including Seats on Private Planes, Semi-Private Seats, Empty-Leg Charters, and First-Class Seats on Commercial Airlines, as well as Flight Interests from Katana Members. You can refine your search by selecting specific filters like "Seats," "Charters," or "Interests" to pinpoint exactly what you're looking for. Next to each flight option, you'll see a profile picture or a Ninja icon, indicating whether the Originator of the flight is a connected Friend, part of a shared Group, or an unconnected user. Below, we've expanded on each category to help you understand your options:
            </Col>

            <Col lg="12" md="12" sm="12" style={{ marginTop: '20px' }}>
              <h3 style={{ fontWeight: "bolder" }}></h3>
              <div id="semi-private-seats" style={{ fontWeight: '600' }}>Seats on Private Planes</div>
              <div>This category includes individual seats available on private flights that have already been booked. These seats are offered either by Katana Members who have excess capacity on their private plane or by one of our scheduled service commercial partners providing exclusive access to premium seating. When a Katana Member lists a flight with extra seats, you can book one at a set price, enjoying the full luxury of private aviation—think plush interiors, personalized service, and private terminals—without the need to charter an entire jet. This option makes elite travel more accessible and cost-effective, connecting you with others already heading your way.
              </div>
              <br />
              <div id="empty-leg-charters" style={{ fontWeight: '600' }}>Semi-Private Seats</div>
              <div>Fly with a touch of exclusivity through our premium commercial partners like XO, Aero, and Bark Air. Semi-private seats offer a hybrid experience, blending the convenience of private aviation with the reliability of scheduled commercial flights. Expect fewer passengers, access to private terminals, expedited check-in, and a first-class-level experience onboard, including spacious seating and top-tier amenities. This option elevates traditional commercial travel, reducing the crowds and chaos while maintaining a stress-free, luxurious journey tailored to discerning travelers.</div>
              <br />
              <div id="first-commercial-airlines" style={{ fontWeight: '600' }}>Empty-Leg Charters</div>
              <div>Charters encompass two distinct opportunities: empty-leg flights and full Member charters. Empty-leg charters are one-way flights available at unbeatable prices when an aircraft needs to reposition after dropping off passengers or before picking up new ones. These flights offer the full private jet experience—privacy, flexibility, and luxury—at a fraction of the usual cost. Additionally, Member charters allow you to book an entire plane (not just seats) for your own use, ideal for groups or those seeking complete control over their travel schedule. Whether it's an empty-leg deal or a bespoke charter, this category delivers premium travel with exceptional value.</div>
              <br />
              <div id="Flight-Interest" style={{ fontWeight: '600' }}>First-Class Seats on Commercial Airlines</div>
              <div>For those who prefer the structure of commercial aviation with a luxurious twist, this option provides access to first-class seats on flights operated by our partner commercial carriers. Enjoy the best that commercial airlines have to offer: expansive, comfortable seating, gourmet in-flight dining, priority boarding, and exclusive airport lounge access. These seats cater to travelers who value convenience and sophistication, offering a refined experience within the broader commercial network while still benefiting from Katana's curated partnerships</div>
              <br />
              <div id="" style={{ fontWeight: '600' }}>Flight Interest</div>
              <div>Additionally, the Flight Interest feature lets you connect with Katana Members who are exploring similar travel plans. If no existing flight matches your needs, you can signal your interest, and others with overlapping destinations and dates can join forces to share the cost of booking a plane. This collaborative approach makes private travel more affordable and builds a community of like-minded travelers. Use the filters and icons to navigate the options and find the perfect fit for your journey!</div>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>REGISTER</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              <h3>It’s fast & easy:</h3>
              <ul className="ml-5">
                <li>Name</li>
                <li>Email</li>
                <li>Password</li>
                <li>”Home” - most frequented route origin</li>
                <li>“Away” - most frequented destination</li>
              </ul>
            </Col>
            {/* <Col lg="12" md="12" sm="12">
              When registering, in addition to your mobile number, we ask you to
              provide your name, email, most frequented route origin (“Home”),
              and destination (“Away”). Providing a Home and Away in your
              profile enables notification via SMS when Seats are added from
              your Home destination, as well as Flight Interest created between
              your Home and Away destinations. In large cities with multiple
              Airports like New York City, you will be notified when flights
              from any New York area airports (i.e., White Plains, Teterboro,
              etc.) are added. You can always change your Home and Away in your{" "}
              <a
                href="https://www.flykatana.com/myProfile"
                className="text-dark"
              >
                Profile
              </a>{" "}
              and if you don’t want to be alerted, simply leave these blank. You
              may also add a profile picture by clicking on the Ninja and
              smiling away!
            </Col> */}
            <Col lg="12" md="12" sm="12">
              When registering, in addition to your mobile number, we ask you to provide your name, email,
              most frequented route origin (“Home”), and destination (“Away”). Providing a Home and Away in
              your profile enables notification via SMS when Seats are added from your Home destination, as
              well as Flight Interest created between your Home and Away destinations. In large cities with
              multiple Airports like New York City, you will be notified when flights from any New York area
              airports (i.e., White Plains, Teterboro, etc.) are added. You may edit your profile anytime by
              clicking on the {" "}
              <a
                href="https://www.flykatana.com/myProfile"
              >
                Profile
              </a>{" "} picture or accessing it through the Menu (three horizontal lines) at the top
              right. You can always change your Home and Away in your Profile, and if you don’t want to be
              alerted, simply leave these blank. You may also add a profile picture by clicking on the Ninja
              and smiling away!
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>SERVICE TIERS</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              <h3>Choose from:</h3>
              <ul className="ml-5">
                <li>Tier 1 - “Basic” access only to commercial flights</li>
                <li>Tier 2 - “Flyer” access most Katana features</li>
                <li>Tier 3 - “Operator” access Flyer features and list charters</li>
              </ul>
            </Col>
            <Col lg="12" md="12" sm="12">
              You   must   be   a   Tier   2   Member   (“Flyer”)   to   unlock   most   value-added   features.   Our   Tier   1
              Members (“Basic”) may book seats with our commercial scheduled service partners, including
              Aero, Blade, and XO, to name a few. If you are a Charter Broker or Operator and wish to list
              whole planes, you must be a Tier 3 Member (“Operator”). All three levels  are   free   for   three
              months, so you can take Katana for a “Test Flight.” If you choose an Annual Membership, you
              can realize real savings, whereas a Monthly Membership is more expensive, but you can cancel
              it monthly.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>FRIENDS</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              Everything is more fun with friends! When you press <a
                href="https://www.flykatana.com/myFriends"
              >
                Friends
              </a>  , you will first see a list of friends
              you invited and they accepted. Next, you will see a list of Friends you invited who haven’t yet
              accepted your invitation to Join Katana. You can easily give them another nudge via email or
              text reminder. Flights originated by your Friends will be indicated by their profile picture next to
              their flight. You may Add friends by entering their number in the prompt or selecting them from
              your Google or Outlook contacts. You can even search your Google or Outlook contacts for
              people who are already Katana members and easily invite them to be Katana Friends.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>GROUP</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              Create your very own{" "}
              <a
                href="https://www.flykatana.com/myGroups"
                className="text-dark"
              >
                Group
              </a>{" "}
              and allow Members of that Group to be automatically interconnected
              whether they are friends or not. This way, individual group
              members don’t need to connect individually. The creator of the
              group will control who is invited to join the Group. When
              searching for a relevant flight, you will see the Group logo if
              the flight originated from another Group Member who isn’t a
              friend.
            </Col>
          </Row>
          {/* <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{fontWeight:'900'}}>BROWSE</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              Once registered and logged in, you are ready to discover flights
              and interact with other Members. You can browse the offering of
              Seats and Flights available on the network via the Home page.
              Seats Available indicates that a Katana Member is offering excess
              seats on an already booked flight at a set price. Flight Interest
              means that a Katana Member is interested in finding someone with
              similar flight interests to share the expense of a plane. Next to
              each flight is a profile picture or a Ninja, indicating the
              Originator of the Flight is connected (as a{" "}
              <a
                href="https://docs.google.com/document/d/1TdGUOPAIcsff8j3Kfr5NQM-blXfewPInOhy-F5eACww/edit?pli=1#bookmark=id.sgdpqbetnapp"
                className="text-dark"
              >
                Friend
              </a>{" "}
              or in a shared{" "}
              <a
                href="https://docs.google.com/document/d/1TdGUOPAIcsff8j3Kfr5NQM-blXfewPInOhy-F5eACww/edit?pli=1#bookmark=id.n32hezmq2n51"
                className="text-dark"
              >
                Group
              </a>
              ) or not.
            </Col>
          </Row> */}
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>SEARCH</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              Simply enter an Origin, Destination, and Date and press Search. The results will include All Seats, Empty Leg Charters, and Flight Interests in the Katana system. You may narrow the results by pressing the Seats, Charters, and Interest to find what you want. Seats indicate that either a Katana Member or one of our scheduled service commercial partners is offering excess seats on an already booked flight at a set price. It may also include business and first class seats from one of our partner commercial carriers. Charters include empty-leg charters and Member charters where the entire plane (not seats) is available. Flight Interest means that a Katana Member is interested in finding someone with similar flight interests to share the expense of a plane before booking. Next to each flight is a profile picture or a Ninja, indicating whether the Originator of the Flight is connected (as a Friend or in a shared Group).
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>INQUIRE/CHAT</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              If there is a Seat, Charter, or Flight Interest that suits your needs, simply press the Inquire (or
              Book   Now)   button.   Suppose   the   Seat,   Charter,   or   Flight   Interest   was   posted   by   a   Member
              (Originator). In that case, the Inquirer will be directed to send a Chat request with the relevant flight details to the  Originator. The Originator will be notified of the Inquiry via SMS and directed
              to Chat with the Inquirer. The number next to 'Inquire' represents the number of Inquiries the
              flight   has   already   received   from   other   Members.   You   will   see   the   number of unopened
              messages on the Home page next to your Profile icon and may access all Chats via the Main
              Menu.
              <br />
              <br />
              If the Seat that interests you is listed by one of our Commercial Partners (Aero, Blade, XO, etc.)
              simply Press Book Now, and you will be directed to our partner's site to book there. The data on
              Katana should match the data on our partner’s site (date, seats available, and price), but there
              may be discrepancies...sorry! If an Empty Leg Charter interests you, Katana will contact the
              operator on your behalf and confirm availability and price.
            </Col>
          </Row>
          {/* <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{fontWeight:'900'}}>CHARTER</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              In the Charter tab, you will see potential empty-leg charter
              options from our Operator partners. Please note that Empty legs
              are not scheduled flights; therefore, the flight and/or the
              price may not be available. Additionally, Origin and Destination
              locations may be flexible and negotiable. Simply Press Inquire,
              and Katana will contact the Operator on your behalf.
            </Col>
          </Row> */}
          {/* <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{fontWeight:'900'}}>INTERESTS</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              If the Flight Interest suits your needs, simply press the Inquire
              button, and the Member who posted it will be notified of your
              interest via SMS. From there, you and the poster can communicate,
              strike a deal and look to coordinate the booking of a flight.
              The number next to 'Inquire' represents the number of Inquires
              the flight has already received from other Members.
            </Col>
          </Row> */}
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>ADD FLIGHT</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              If your Search does not yield matches, you can Add the Flight Interest so others can see it.
              When added, all relevant Members with the same city pair parameters will be notified of your
              interest via SMS. You can see your flights in the {" "}
              <a
                href="https://www.flykatana.com/myFlights"
              >
                My Flights
              </a>{" "} tab, where you can Edit or Delete
              the Seats, Charter, or Flight interests as necessary.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>GET PRICE</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              If you need to charter a plane, we are happy to solicit pricing from our Broker partners. Press
              Get Price next to the relevant flight, either from a new Search or a flight you previously posted in
              My   Flights.   However,   one-way   legs   should   be   booked   within   ten   days   of   travel   for   the   best
              pricing. Katana will take a small percentage of the Broker’s commission for the introduction.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>SELL SEATS</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              If you have already booked a flight and have extra seats you’d like to get paid for, then Katana
              is the perfect place to sell them. Go to the Sell Seats tab and enter the flight details, including
              plane type, available seats, and price per seat. Pricing should be as you wish. When added, all
              relevant Members (Public or Private) with the same Origin set as their Home will be notified via
              SMS.   Further,   these   seats   will   be   visible   to   the   Katana   community.   You   can   always   Edit   or
              Delete the flight as necessary in My Flights. If a Member reaches out, you should Chat with
              them directly to ensure they are a good fit (pets, luggage, etc.), and arrange for payment in
              exchange for adding them to the manifest. Katana may accept and remit payments for a small
              fee if desired.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2 style={{ fontWeight: '900' }}>QUESTIONS</h2>
            </Col>
          </Row>
          <Row className="mb-4" style={{ fontSize: "16px" }}>
            <Col lg="12" md="12" sm="12">
              If you have any questions, comments, or suggestions, please feel
              free to remit them to{" "}
              <a href="mailto:info@flykatana.com">
                info@flykatana.com
              </a>{" "}
              or message me at{" "}
              <a href="tel:9293189292">
                (929) 318-9292
              </a>
              .
              <br />
              <br />
              Remember, Katana is your first stop before you book your private
              plane. Safe travels!
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default HowTo;
