import axios from "../libs/axios";

const AllUnregFlights = async () => {
  const { data } = await axios.request({
    url: "/GetHomePageFlightsUnregistered",
    method: "post",
    data: {},
  });
  return data;
};

const UnregSearchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/SearchFlightsUnregistered",
    method: "post",
    data: searchData,
  });
  return data;
};

const UnregveryFlexibleSearchFlights = async (searchData) => {
  const { data } = await axios.request({
    url: "/SearchFlightsUnregisteredVeryFlexible",
    method: "post",
    data: searchData,
  });
  return data;
};

const allRegFlights = async (userid, user) => {
  const { data } = await axios.request({
    url: "/GetHomePageFlights",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      userid: parseInt(userid),
    },
  });
  // console.log(data);
  return data;
};

const searchFlights = async (searchData, user) => {
  const { data } = await axios.request({
    url: "/Searchflights",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: searchData,
  });
  return data;
};

const searchFlyEasyFlights = async (searchData) => {
  var flyEasyData;
  if (searchData.dep_date) {
    const today = new Date(searchData.dep_date);
    // Calculate the dates 2 days before and after today
    const twoDaysBefore = new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000);
    const twoDaysAfter = new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000);

    // Format the dates as YYYY-MM-DD strings
    const startDate = twoDaysBefore.toISOString().split("T")[0];
    const endDate = twoDaysAfter.toISOString().split("T")[0];

    console.log(startDate, endDate); // Output: "2023-03-14" "2023-03-20"

    flyEasyData = {
      from_airport: searchData.from_icao,
      to_airport: searchData.to_icao,
      radiusNM: 50,
      dateRange: startDate + ":" + endDate,
      //apiKey: process.env.REACT_APP_FLYEASY_KEY
    };
  } else {
    flyEasyData = {
      from_airport: searchData.from_icao,
      to_airport: searchData.to_icao,
      radiusNM: 50,
      //apiKey: process.env.REACT_APP_FLYEASY_KEY
    };
  }
  const { data } = await axios.request({
    url: "/SearchFlyEasyFlights",
    method: "post",
    data: flyEasyData,
  });
  return data;
};

const searchVolatoCharters = async (searchData) => {
  var volatoData = {
    from_airport: searchData.from_icao,
    to_airport: searchData.to_icao,
    dep_date: searchData.dep_date,
  }
  const { data } = await axios.request({
    url: "/SearchVolatoCharters",
    method: "post",
    data: volatoData,
  });
  return data;
};

const searchDuffelFlights = async (searchData) => {
  var duffelData = {
    from_airport: searchData.from_airport,
    to_airport: searchData.to_airport,
    dep_date: searchData.dep_date,
    passengers: searchData.passenger
  }
  const { data } = await axios.request({
    url: "/SearchDuffelFlights",
    method: "post",
    data: duffelData,
  });
  return data;
};

const veryFlexibleSearchFlights = async (searchData, user) => {
  const { data } = await axios.request({
    url: "/SearchFlightsVeryFlexible",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: searchData,
  });
  return data;
};

const getFlightDetail = async (id, user) => {
  const { data } = await axios.request({
    url: "/GetFlightInfoWeb",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      flightno: parseInt(id, 10),
    },
  });
  return data;
};

const getMyFlights = async (mobile, user) => {
  const { data } = await axios.request({
    url: "/GetMyFlights",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      mobile: parseInt(mobile, 10),
    },
  });
  return data;
};

const postFlight = async (searchData, user) => {
  const { data } = await axios.request({
    url: "/PostFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: searchData,
  });
  return data;
};

const deleteFlight = async (mobile, id, user) => {
  const { data } = await axios.request({
    url: "/DeleteFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { mobile: mobile, flightno: id },
  });
  return data;
};
const addFlight = async (flightData, user) => {
  const { data } = await axios.request({
    url: "/AddFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: flightData,
  });
  return data;
};

const joinFlightApi = async (flightData, user) => {
  const { data } = await axios.request({
    url: "/JoinFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: flightData,
  });
  return data;
};

const GetBulletin = async () => {
  const { data } = await axios.request({
    url: "/GetBulletin",
    method: "post",
    data: {},
  });
  return data;
};

const FlightPrice = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/BookFlightPrice",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const GetMyFlightPrice = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/MyFlightGetPrice",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const UpdateFlightData = async (Flightdata, user) => {
  const { data } = await axios.request({
    url: "/EditMyFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: Flightdata,
  });
  return data;
};

const FlightClickTrack = async (Flightdata) => {
  const { data } = await axios.request({
    url: "/FlightClickTrack",
    method: "post",
    data: Flightdata,
  });
  return data;
};

const getNearByAirport = async (location) => {
  const { data } = await axios.request({
    url:
      "/getNearbyAirports?lat=" +
      location.latitude +
      "&lng=" +
      location.longitude,
    method: "get",
  });
  return data;
};

//DUFFEL
const createPaymentIntent = async (paymentData, user) => {
  const { data } = await axios.request({
    url: "/CreatePaymentIntent",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: paymentData,
  });
  return data;
};

const confirmPayment = async (paymentIntentId, user) => {
  const { data } = await axios.request({
    url: "/ConfirmPayment",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { payment_intent_id: paymentIntentId },
  });
  return data;
};

const bookDuffelFlight = async (bookingData, user) => {
  const { data } = await axios.request({
    url: "/BookDuffelFlight",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: bookingData,
  });
  return data;
};

const updateDuffelBooking = async (updateData, user) => {
  const { data } = await axios.request({
    url: "/UpdateDuffelBooking",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: updateData,
  });
  return data;
};

const cancelDuffelBooking = async (cancelData, user) => {
  const { data } = await axios.request({
    url: "/CancelDuffelBooking",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: cancelData,
  });
  return data;
};

const myBookings = async (userid, user) => {
  const { data } = await axios.request({
    url: "/GetMyBookings",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid }
  });
  return data;
};
const myBookingDetails = async ({ userid, booking_id }, user) => {
  const { data } = await axios.request({
    url: "/GetMyBookingsById",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid, booking_id: booking_id }
  });
  return data;
};

const getAllBarkFlights = async (userid, user) => {
  const { data } = await axios.request({
    url: "/GetAllBarkFlights",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      userid: parseInt(userid),
    },
  });
  // console.log(data);
  return data;
};

const searchBarkFlights = async (searchData, user) => {
  const { data } = await axios.request({
    url: "/searchBarkFlights",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      from_city: searchData.from_city,
      to_city: searchData.to_city,
      from_airport: searchData.from_airport,
      to_airport: searchData.to_airport,
      dep_date: searchData.dep_date,
      userid: parseInt(user.userid),
    },
  });
  // console.log(data);
  return data;
};
const checkPhoeNumberValidDuffelBooking = async (user, phoneNumber) => {
  console.log(user, "user booklig call");

  const { data } = await axios.request({
    url: "/ValidateNumber",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { number: phoneNumber },
  });
  return data;
}
const downloadBookingDetailsPDF = async (booking_id, user) => {
  const res = await axios.request({
    url: "/DownloadTicket",
    method: "post",
    headers: {
      Authorization: `Bearer ${user?.jwt}`
    },
    data: { bookingId: booking_id },
    responseType: "blob",
  });
  return res?.data;
};
export const useFlightData = () => ({
  AllUnregFlights,
  UnregSearchFlights,
  UnregveryFlexibleSearchFlights,
  allRegFlights,
  searchFlights,
  searchFlyEasyFlights,
  searchVolatoCharters,
  searchDuffelFlights,
  veryFlexibleSearchFlights,
  getFlightDetail,
  getMyFlights,
  postFlight,
  deleteFlight,
  addFlight,
  joinFlightApi,
  GetBulletin,
  FlightPrice,
  GetMyFlightPrice,
  UpdateFlightData,
  FlightClickTrack,
  getNearByAirport,
  createPaymentIntent,
  confirmPayment,
  bookDuffelFlight,
  updateDuffelBooking,
  cancelDuffelBooking,
  myBookings,
  myBookingDetails,
  getAllBarkFlights,
  searchBarkFlights,
  checkPhoeNumberValidDuffelBooking,
  downloadBookingDetailsPDF
});
