import React, { useEffect, useRef, useState } from 'react'

const BrowseCardSection = () => {
    const carouselRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    // Check for mobile device
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);


    const carouselData = [
        {
            title: "Seats on Private Planes ",
            imageSrc: require("../assets/images/Browse.jpg").default,
            redirect: "seats-on-private-planes",
            description:
                "Fly private without chartering the whole jet. Book individual seats on exclusive private flights listed by other Katana Members. Enjoy all the benefits of private travel for less. "
        },
        {
            title: "Semi-Private Seats ",
            imageSrc: require("../assets/images/Book.jpg").default,
            redirect: "semi-private-seats",
            description:
                "Fly semi-private with premium commercial partners like XO, Aero, and Bark Air. Enjoy a first-class experience with fewer passengers, private terminals, and a stress-free journey-elevating commercial travel to a whole new level. "
        },
        {
            title: "Empty-Leg Charters ",
            imageSrc: require("../assets/images/Relax.jpg").default,
            redirect: "empty-leg-charters",
            description:
                "Take advantage of exclusive one-way empty-leg flights at unbeatable prices. Fly private for less by booking available aircraft repositioning flights, offering the luxury of private travel at a fraction of the cost."
        },
        {
            title: "First-Class Commercial Seats",
            imageSrc: require("../assets/images/Relax.jpg").default,
            redirect: "first-commercial-airlines",
            description: "Experience the pinnacle of commercial travel with first-class seats on top-tier airlines. Enjoy spacious seating, gourmet dining, priority boarding, and exclusive lounge access for a luxurious and comfortable journey."
        },
        {
            title: "Flight Interest",
            imageSrc: require("../assets/images/Relax.jpg").default,
            redirect: "Flight-Interest",
            description: "Connect with Katana Members who share your travel plans. Express interest in a flight and team up to share the cost of booking a private plane, making luxury travel more affordable and collaborative."
        }
    ];
    const data = isMobile ? carouselData : [...carouselData, ...carouselData, ...carouselData, ...carouselData];
    // Modified auto scroll functionality
    useEffect(() => {
        if (isMobile) return; // Disable auto-scroll on mobile

        const interval = setInterval(() => {
            if (!isDragging && carouselRef.current) {
                const cardWidth = carouselRef.current.clientWidth / 3; // 3 cards per view
                const nextIndex = (currentIndex + 1) % data.length;
                carouselRef.current.scrollTo({
                    left: nextIndex * cardWidth,
                    behavior: 'smooth',
                });
                setCurrentIndex(nextIndex);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex, isDragging, isMobile]);


    // Modified mouse event handlers
    const handleMouseDown = (e) => {
        if (isMobile) return;
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        if (isMobile) return;
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        if (isMobile) return;
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isMobile || !isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };
    return (
        <div
            className="Browse-card-section"
            ref={carouselRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            style={{
                display: 'flex',
                overflow: 'hidden',  // Hide cut-off cards
                scrollSnapType: 'x mandatory',
                gap: '1rem',
            }}
        >
            {data.map((item, index) => (
                <div
                    key={index}
                    className="homecard"
                    onClick={() => window.location.href = `/how-to#${item.redirect}`}
                    style={{
                        flex: '0 0 calc(100% / 3 - 1rem)',  // Fit 3 cards exactly
                    }}
                >
                    <div className="home-card-image-wrapper">
                        <h3 className="home-card-title">{item.title}</h3>
                    </div>
                    <div className="home-card-content">
                        <div className="home-card-description" style={{ color: "white" }}>
                            {item.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default BrowseCardSection