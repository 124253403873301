import React from 'react'
import forbes from "../assets/footerimage/forbes.png";
import imageSimpleFlying from "../assets/footerimage/simple-flying.svg";
import imageMedium from "../assets/footerimage/medium.svg";
import imageCityBig from "../assets/footerimage/city-big.png";
import imageTimeOut from "../assets/footerimage/Time_Out.jpg";
import imageBusinessAirNews from "../assets/footerimage/buisness_air_news.svg";
import { useMediaQuery } from '@mui/material';
const FooterImagesection = () => {
    const isBelowPcDevice = useMediaQuery("(max-width:1200px)");

    const images = [
        { src: forbes, alt: "forbes Logo", link: "https://www.forbes.com/sites/douggollan/2023/04/08/two-miami-private-jet-sharing-start-ups-are-delayed-for-takeoff/" },
        { src: imageSimpleFlying, alt: "Simple Flying Logo", link: "https://simpleflying.com/katana-private-jet-marketplace-feature/" },
        { src: imageMedium, alt: "Medium Logo", link: "https://medium.com/@thedinaaletras/b660be745175" },
        { src: imageCityBig, alt: "City Big", link: "https://www.citybiz.co/article/655716/qa-with-bryan-verona-founder-of-katana/#:~:text=KATANA%20is%20a%20pioneering%20private,reducing%20waste%20from%20underutilized%20flights." },
        { src: imageTimeOut, alt: "Time Out Magazine", link: "https://www.timeout.com/usa/travel/semi-private-flights-usa#google_vignette" },
        { src: imageBusinessAirNews, alt: "Business Air News Logo", link: "https://www.businessairnews.com/viewer/371/html5/index.html?pn=13" }
    ];
    const FooterImageLink = isBelowPcDevice ? [...images, ...images, ...images] : images;
    return (
        <div className={isBelowPcDevice ? "marquee-container" : ""}>
            <div className={isBelowPcDevice ? "marquee" : "Footer-img-logo"}>
                {FooterImageLink.map((image, index) => (
                    <a key={index} href={image.link} target="_blank" rel="noopener noreferrer">
                        <img src={image.src} alt={image.alt} />
                    </a>
                ))}
            </div>
        </div>
    )
}

export default FooterImagesection