import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
//import LoginAndSignUp from "./LoginAndSignUp";
import "./PricingPlans.css";
import { ToggleSlider } from "react-toggle-slider";
import { authAndUserData } from "../data/AuthAndUserData";
import { useLocalStorage } from "../utils/useLocalStorage";
import { Alert, Label } from "reactstrap";
import axios from "../libs/axios";
import { useHistory } from "react-router-dom";
import { FormControl, Input, Snackbar } from "@material-ui/core";

const currentDate = new Date();

function PricingPlans({ pathType, setPathType, OpenLogin }) {
  const planMonthly = [
    {
      id: 1,
      top: "Tier 1",
      planName: "Free Plan",
      price: 0,
      duration: "mo",
      duration_name: "Monthly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
      ],
    },
    {
      id: 2,
      top: "Tier 2",
      planName: "Standard Plan",
      priceId: process.env.REACT_APP_STANDARD_PRICE_MONTHLY,
      price: 10,
      duration: "mo",
      duration_name: "Monthly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
        "Add Flight Interests",
        "Book Non-Commercial Seats",
        "Connect on Flight Interests",
        "Add Friends",
        "Create & Join Groups",
      ],
    },
    {
      id: 3,
      top: "Tier 3",
      planName: "Premium Plan",
      priceId: process.env.REACT_APP_PREMIUM_PRICE_MONTHLY,
      price: 20,
      duration: "mo",
      duration_name: "Monthly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
        "Add Flight Interests",
        "Book Non-Commercial Seats",
        "Connect on Flight Interests",
        "Add Friends",
        "Create & Join Groups",
        "Add Charter Flights",
        "Multiple Party Notification",
        "Branding Presence",
      ],
    },
  ];

  const planYearly = [
    {
      id: 1,
      top: "Tier 1",
      planName: "Free Plan",
      price: 0,
      duration: "yr",
      duration_name: "Yearly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
      ],
    },
    {
      id: 2,
      top: "Tier 2",
      planName: "Standard Plan",
      priceId: process.env.REACT_APP_STANDARD_PRICE_YEARLY,
      price: 100,
      duration: "yr",
      duration_name: "Yearly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
        "Add Flight Interests",
        "Book Non-Commercial Seats",
        "Connect on Flight Interests",
        "Add Friends",
        "Create & Join Groups",
      ],
    },
    {
      id: 3,
      top: "Tier 3",
      planName: "Premium Plan",
      priceId: process.env.REACT_APP_PREMIUM_PRICE_YEARLY,
      price: 200,
      duration: "yr",
      duration_name: "Yearly",
      features: [
        "Register",
        "Browse Flights",
        "Search Flights",
        "Book Commercial Flights",
        "Add Flight Interests",
        "Book Non-Commercial Seats",
        "Connect on Flight Interests",
        "Add Friends",
        "Create & Join Groups",
        "Add Charter Flights",
        "Multiple Party Notification",
        "Branding Presence",
      ],
    },
  ];
  const { t } = useTranslation();
  const [isYearly, setisYearly] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [trialDays, setTrialDays] = useState(90);
  const [isApplied, setIsApplied] = useState(false);
  const [isInvalid, setIsInValid] = useState(false);
  const [plans, setPlans] = useState(planMonthly);
  const [user, setUser] = useLocalStorage("auth", {});
  const [isSubscriptionChanged, setSubscriptionChanged] = useState(false);
  const [isSubscriptionCancelled, setSubscriptionCancelled] = useState(false);
  const { GetUserDetailsForHome, PlansShowed, ApplyCoupon } = authAndUserData();

  // console.log("User: " + JSON.stringify(user));

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
  let history = useHistory();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setIsPageLoaded(true);
      PlansShowed(user.userid);
      GetUserDetailsonHomePage(user.userid);
      //user.subscription_frequency == "Monthly"? setisYearly(false) : setisYearly(true);
    }
  }, [isLoaded]);

  const handleInputChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var code_response = await ApplyCoupon(coupon, user);
    console.log(code_response);
    if (code_response.length > 0) {
      setTrialDays(code_response[0].trial_days);
      // console.log(`Coupon code ${coupon} applied!\nTrial days ${trialDays}`);
      setIsInValid(false);
      setIsApplied(true);
      //document.getElementById('coupon-success').value = 'Coupon Applied successfully.';
    } else {
      setIsApplied(false);
      setIsInValid(true);
    }
  };

  const togglePlanDuration = (event) => {
    isYearly ? setisYearly(false) : setisYearly(true);
    if (isYearly) setPlans(planMonthly);
    else setPlans(planYearly);
  };

  async function GetUserDetailsonHomePage(userid) {
    const userData = await GetUserDetailsForHome(userid, user);
    console.log("User Data: " + userData);
    setUser({ ...userData, jwt: user.jwt });
  }

  async function Subscribe(plan) {
    const subscription_data = {
      email: user.email,
      userid: user.userid,
      price: plan.priceId,
      customer: user.stripe_customer_id,
      trial: user.is_new ? true : false,
      success_url: process.env.REACT_APP_DOMAIN + "/home",
      cancel_url: process.env.REACT_APP_DOMAIN + "/plans",
    };

    if (user.is_new) subscription_data.trial_days = trialDays;

    try {
      if (plan.id == 1 && user.subscription_plan_id == 1) {
        if (localStorage?.getItem('from') && localStorage?.getItem("to") && localStorage?.getItem("Inquire")) {
          let date = localStorage?.getItem('date')
          if (date) {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}?date=${date}`;
            localStorage?.removeItem('date')
          } else {
            window.location = `/routes/${localStorage?.getItem('from')}-${localStorage?.getItem("to")}`;

          }
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          localStorage?.removeItem("Inquire")
        } else {
          localStorage?.removeItem("to")
          localStorage?.removeItem("from")
          window.location = "/";
        }
        //history.push(`/home`);
      }

      if (plan.id == 1) {
        axios
          .request({
            url: "/CancelSubscriptionNew",
            method: "post",
            headers: {
              Authorization: `Bearer ${user.jwt}`
            },
            data: {
              userid: user.userid,
              subscription_id: user.subscription_id,
              new_priceId: plan.priceId,
              plan_type: plan.top + plan.duration_name,
            },
          })
          .then((response) => {
            setSubscriptionCancelled(true);
            //window.location.reload();
            //window.location.href = response.data.url;
          });
      }

      if (
        user.is_new == false &&
        plan.id != 1 &&
        (user.subscription_plan_id == 2 || user.subscription_plan_id == 3)
      ) {
        axios
          .request({
            url: "/ChangeSubscriptionNew",
            method: "post",
            headers: {
              Authorization: `Bearer ${user.jwt}`
            },
            data: {
              subscription_plan_id: plan.id,
              userid: user.userid,
              subscription_id: user.subscription_id,
              new_priceId: plan.priceId,
              plan_type: plan.top + " " + plan.duration_name,
              subscription_frequency: plan.duration_name,
            },
          })
          .then((response) => {
            setSubscriptionChanged(true);
            window.location.reload();
            //window.location.href = response.data.url;
          });
      } else {
        if (plan.id != 1) {
          axios
            .request({
              url:
                process.env.REACT_APP_SERVICE_BASE_URL +
                "api/create-checkout-session",
              method: "post",
              data: subscription_data,
            })
            .then((response) => {
              window.location.href = response.data.url;
            });
        }
      }
    } catch (err) {
      console.log("Stripe subscription error" + err);
    }
  }

  //GetUserDetailsonHomePage(user.userid);

  // useEffect(() => {
  //   console.log("useEffect");
  //   if(user.userid != undefined)
  //   {
  //     GetUserDetailsonHomePage(user.userid);
  //   }
  // },[user]);

  return (
    <>
      <div
        style={{
          display: "flex",
          "justify-content": "center",
          alignItems: "center",
          paddingTop: "40px",
        }}
      >
        <div style={{ flexBasis: "33.33%", textAlignLast: "right" }}>
          <p
            className="toggle-label"
            style={{ paddingRight: "10px", marginBottom: "0px" }}
          >
            Monthly
          </p>
        </div>
        <ToggleSlider
          style={{ flexBasis: "33.33%" }}
          class="plans-toggle"
          //handleBackgroundColorActive	='#FFE600'
          barBackgroundColorActive="#3B5E75"
          onToggle={togglePlanDuration}
        />
        <div style={{ flexBasis: "33.33%" }}>
          <p
            className="toggle-label"
            style={{ paddingLeft: "10px", marginBottom: "0px" }}
          >
            Yearly <i>(Save $$)</i>
          </p>
        </div>
      </div>
      {/* <div className="coupon-code">
      <FormControl >
      <Label>Have a coupon code?</Label>
      <Input
      type="text"
      placeholder="coupon"              
      value={coupon}>
      </Input>
      <button class="coupon-button">Apply</button>
      </FormControl>
      </div> */}
      {user.is_new == true && (
        <form onSubmit={handleSubmit} className="coupon-form">
          <label htmlFor="coupon-input" className="coupon-label">
            Have a coupon code?
          </label>
          <div className="coupon-input-container">
            {" "}
            {/* Add container with CSS class */}
            <input
              type="text"
              id="coupon-input"
              value={coupon}
              onChange={handleInputChange}
              className="coupon-input"
            />
            <button type="submit" className="coupon-button">
              Apply
            </button>
          </div>
          {isApplied && (
            <p className="coupon-success">
              Coupon applied successfully!
              <br />
              You get {trialDays} days FREE TRIAL.
            </p>
          )}
          {isInvalid && <p className="coupon-fail">Invalid Coupon code.</p>}
        </form>
      )}
      {isSubscriptionChanged && (
        <Alert className="alertYellow">
          Subscription changed successfully.
        </Alert>
      )}
      {isSubscriptionCancelled && (
        <Alert className="alertYellow">
          Subscription cancelled successfully. You will be shifted to the Tier-1
          Basic Plan at the end of Trial
        </Alert>
      )}
      <div class="pricing-container">
        {plans.map((plan, index) => (
          <div class="plan">
            <div class="plan-header">
              <div class="plan-image">
                <img
                  src={
                    require("../assets/images/Tier_" + plan.id + ".jpg").default
                  }
                  alt={"Plan " + plan.id}
                />
                {user.subscription_plan_id == plan.id && (
                  <div class="img-overlay">
                    Currently Active{" "}
                    {user.subscription_plan_id != 1 &&
                      user.subscription_frequency}
                  </div>
                )}
              </div>
              <h2 class="plan-title">{plan.top}</h2>
              {/* <h2 class="plan-title">{plan.planName}</h2> */}
              <div class="plan-price">
                ${plan.price}
                <span>/{plan.duration}</span>
                {isYearly && plan.id == 1 && (
                  <p>
                    <span> </span>
                  </p>
                )}
                {isYearly && plan.id == 2 && (
                  <p>
                    <span> (Save $20)</span>
                  </p>
                )}
                {isYearly && plan.id == 3 && (
                  <p>
                    <span> (Save $40)</span>
                  </p>
                )}
              </div>
            </div>
            <button
              class="plan-button"
              // style={{ color: plan.id == 1 ? 'black' : '' }}
              disabled={
                user.subscription_plan_id == plan.id &&
                  user.subscription_frequency == plan.duration_name &&
                  !(user.subscription_plan_id == 1 && plan.id == 1)
                  ? true
                  : false
              }
              onClick={() => Subscribe(plan)}
            >
              {(user?.is_paid && (new Date(user?.subscription_end) > new Date())) ? (plan?.duration_name === user?.subscription_frequency && plan?.id === user?.subscription_plan_id) ? "Currently Active" : "Switch plan" : (plan?.id == 1 || user?.is_paid) ? "Get Started" : "Get Started For Free"}
            </button>
            <ul class="plan-features">
              {/* {plan.features.map((feature) =>
            <li>{feature}</li>
            )} */}
              {(plan.id == 1 || plan.id == 2 || plan.id == 3) && (
                <>
                  <li>Register</li>
                  <li>Browse Flights</li>
                  <li>Search Flights</li>
                  <li>Book Commercial Flights</li>
                </>
              )}
              {plan.id == 2 && (
                <>
                  <li>
                    <b>Book Non-Commercial Seats</b>
                  </li>
                  <li>
                    <b>Add Flight Interests</b>
                  </li>
                  <li>
                    <b>Chat with Members</b>
                  </li>
                  <li>
                    <b>Connect on Flight Interests</b>
                  </li>
                  <li>
                    <b>Add Friends</b>
                  </li>
                  <li>
                    <b>Create & Join Groups</b>
                  </li>
                </>
              )}
              {plan.id == 3 && (
                <>
                  <li>Book Non-Commercial Seats</li>
                  <li>Add Flight Interests</li>
                  <li>Chat with Members</li>
                  <li>Connect on Flight Interests</li>
                  <li>Add Friends</li>
                  <li>Create & Join Groups</li>
                  <li>
                    <b>Add Charter Flights</b>
                  </li>
                  <li>
                    <b>Multiple Party Notification</b>
                  </li>
                  <li>
                    <b>Branding Presence</b>
                  </li>
                </>
              )}
            </ul>
          </div>
        ))}
        {/* <div class="plan">
    <div class="plan-header">
    <div class="plan-image">
      <img src={require("../assets/images/Tier_1.jpg").default} alt="Plan 1" />
      </div>
      <h2 class="plan-title">Tier 1 - Basic</h2>
      <h2 class="plan-title">Free Plan</h2>
      <div class="plan-price">$0<span>/mo</span></div>
    </div>    
    <button class="plan-button">Get Started</button>
    <ul class="plan-features">
      <li>Register</li>
      <li>Browse Flights</li>
      <li>Search Flights</li>
      <li>Book Commercial Flights</li>
    </ul>  
  </div>
  <div class="plan">
    <div class="plan-header">
    <div class="plan-image">
      <img src={require("../assets/images/Tier_2.jpg").default} alt="Plan 2" />
    </div>
    <h2 class="plan-title">Tier 2 - Flyer</h2>
      <h2 class="plan-title">Standard Plan</h2>
      <div class="plan-price">$20<span>/mo</span></div>
    </div>
    <button class="plan-button">Get Started</button>
    <ul class="plan-features">
      <li>Register</li>
      <li>Browse Flights</li>
      <li>Search Flights</li>
      <li>Book Commercial Flights</li>
      <li>Add Flight Interests</li>
      <li>Book Non-Commercial Seats</li>
      <li>Connect on Flight Interests</li>
      <li>Add Friends</li>
      <li>Create & Join Groups</li>
    </ul>
    
  </div>
  <div class="plan"> */}
        {/* <div class="plan-header">
    <div class="plan-image">
      <img src={require("../assets/images/Tier_3.jpg").default} alt="Plan 3" />      
      </div>
      <h2 class="plan-title">Tier 3 - Operator</h2>
      <h2 class="plan-title">Premium Plan</h2>
      <div class="plan-price">$50<span>/mo</span></div>
    </div>
    <button class="plan-button">Get Started</button>    
    <ul class="plan-features">
    <li>Register</li>
      <li>Browse Flights</li>
      <li>Search Flights</li>
      <li>Book Commercial Flights</li>
      <li>Add Flight Interests</li>
      <li>Book Non-Commercial Seats</li>
      <li>Connect on Flight Interests</li>
      <li>Add Friends</li>
      <li>Create & Join Groups</li>
      <li>Add Charter Flights</li>
      <li>Multiple Party Notification</li>
      <li>Branding Presence</li>      
    </ul> */}

        {/* </div> */}
      </div>
    </>
  );
}

export default PricingPlans;
