
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import airportData from "../data/airport";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import {
  FormGroup,
} from "reactstrap";
import './City.css'

function City({ type, code, setCode, Page, from_city, to_city, IsBarkAir, IsFilterAirportDataByAll = false }) {
  const { t } = useTranslation();
  const [cityCountry, setCityCountry] = useState("");
  // Conditional dataset based on Page value
  const customAirportData = [
    {
      city: "New York, Teterboro NYC",
      country: "United States",
      IATA: "TEB",
      icao_code: "KTEB",
      airport_name: "Teterboro Airport"
    },
    {
      city: "Los Angeles, Van Nuys",
      country: "United States",
      IATA: "VNY",
      icao_code: "KVNY",
      airport_name: "Van Nuys Airport"
    },
    {
      city: "London",
      country: "United Kingdom",
      IATA: "STN",
      icao_code: "EGSS",
      airport_name: "London Stansted Airport"
    },
    {
      city: "Paris",
      country: "France",
      IATA: "LBG",
      icao_code: "LFPB",
      airport_name: "Paris - Le Bourget Airport"
    },
    {
      city: "South Florida, Fort Lauderdale",
      country: "United States",
      IATA: "FLL",
      icao_code: "KFLL",
      airport_name: "Fort Lauderdale-Hollywood International Airport"
    },
    {
      city: "Milwaukee",
      country: "United States",
      IATA: "MKE",
      icao_code: "KMKE",
      airport_name: "General Mitchell International Airport"
    },
  ];

  const [options, setOptions] = useState(IsBarkAir ? customAirportData : airportData)
  const [filterdata, setFilterData] = useState(IsBarkAir ? customAirportData : airportData);
  // useEffect(() => {
  // setOptions(IsBarkAir ? customAirportData : airportData)
  // setFilterData(IsBarkAir ? customAirportData : airportData);
  // }, []);

  // useEffect(() => {
  //   if (IsBarkAir) {
  //     if (type === "from") {
  //       // Show all airports for departure
  //       setFilterData(options);
  //     } else {
  //       // For destination ('to' field):
  //       if (from_city === "TEB" && type !== "from") {
  //         // If departing from TEB, show all airports except TEB
  //         setFilterData(options.filter(item => item.IATA !== "TEB"));
  //       } else {
  //         setFilterData(options.filter(item => item.IATA === "TEB"));
  //       }
  //     }
  //   }
  // }, [type, from_city]);

  useEffect(() => {
    if (code) {
      const airport = options.find(
        (item) => item.IATA === code
      );
      setCityCountry(
        airport ? `${airport?.IATA}-${airport?.city}, ${airport?.country}` : ""
      );
    } else {
      setCityCountry("");
    }
  }, [code, options]);

  const onClickInput = () => {
    setCode("");
  };

  const onTextChange = (event) => {
    setCityCountry(event?.target?.value);
    const filteredData = options.filter(
      (opt) => {
        if ((!(opt?.city === "Albenga") && IsFilterAirportDataByAll && (from_city?.toLowerCase().includes("all") || to_city?.toLowerCase().includes("all")) && (opt?.IATA.toLowerCase().includes("all"))) && !(to_city === "ALL" || from_city === "ALL")) {
          return false
        }
        if (IsBarkAir && (from_city === "TEB" || to_city === "TEB") && opt.IATA === "TEB") {
          return false
        }
        return (
          opt.IATA.toLowerCase().includes(event.target.value.toLowerCase()) ||
          opt.city.toLowerCase().includes(event.target.value.toLowerCase()) ||
          opt.country.toLowerCase().includes(event.target.value.toLowerCase())
        )
      }
    );
    setFilterData(filteredData);
  };

  const SelectOption = (item) => {
    setCode(item?.IATA);
    setCityCountry(`${item?.IATA}-${item?.city}, ${item?.country}`);
  };

  return (
    <div>
      <FormGroup className="mb-3">
        <FormControl fullWidth>
          <InputLabel htmlFor={"city_" + type}>
            <span
              style={{
                color: "#3b5e75",
                fontWeight: "900",
                fontSize: "20px",
              }}
            >
              {type === "from" ? "From" : "To"}
            </span>
          </InputLabel>
          <Input
            id={"city_" + type}
            value={cityCountry}
            onChange={onTextChange}
            onClick={onClickInput}
            onFocus={() => setFilterData(IsBarkAir ? customAirportData.filter(item => (from_city === "TEB" || to_city === "TEB") && item.IATA === "TEB" ? false : true) : airportData)}
            placeholder="Anywhere"
            autoComplete="off"
            startAdornment={
              <InputAdornment position="start">
                <img
                  alt="..."
                  style={{ height: "15px", width: "22.5px" }}
                  src={
                    require(type === "from" ? "../assets/images/icons/plane-take-off.png" : "../assets/images/icons/plane-landing.png").default
                  }
                />
              </InputAdornment>
            }
          />
        </FormControl>
        {code !== null && type === "to" && Page !== "Book" && (
          <FaTimes
            style={{
              position: "absolute",
              bottom: "20px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={(e) => setCode(null)}
          />
        )}
        {code === "" && (
          <div className="Dropdown">
            <ul>
              {filterdata &&
                filterdata.map((item, index) => (
                  <li
                    key={index}
                    // data-value={item?.IATA}
                    onClick={() => SelectOption(item)}
                    value={item?.IATA}
                  >
                    <p>
                      <span>{item?.city},{item?.country}</span>
                      <span>{item?.IATA}</span>
                    </p>
                    <p>{item?.airport_name}</p>
                  </li>
                ))}
              {filterdata?.length < 1 && <li>No Data Found</li>}
            </ul>
          </div>
        )
        }
      </FormGroup >
    </div >
  );
}

export default City;
